<template>
  <b-card
    title="Edit User"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="frmEditParticipant">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Birth Date"
              label-for="birthdate"
            >
              <b-form-input
                id="birthdate"
                v-model="birthDate"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Gender"
              label-for="gender"
            >
              <b-form-input
                id="gender"
                v-model="gender"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Connect Group Category"
              label-for="connect_group_category"
            >
              <b-form-input
                id="connect_group_category"
                v-model="connectGroupCategory"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="First Option Ministry"
            >
              <validation-provider
                #default="{ errors }"
                name="First option ministry"
                rules="required"
              >
                <v-select
                  v-model="firstOptionMinistry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="firstOptionMinistryOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="Object.keys(secondOptionMinistry).length !== 0 || servolutionType === 1"
            cols="12"
          >
            <b-form-group
              label="Second Option Ministry"
            >
              <validation-provider
                #default="{ errors }"
                name="Second option ministry"
                rules="required"
              >
                <v-select
                  v-model="secondOptionMinistry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="secondOptionMinistryOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'servolution-participants', params: { id: eventId } }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import * as moment from 'moment'
import _ from 'lodash'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,

    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      fullPage: false,
      eventId: '',
      name: '',
      birthDate: '',
      gender: '',
      email: '',
      phone: '',
      connectGroupCategory: '',
      firstOptionMinistry: {},
      firstOptionMinistryOptions: [],
      secondOptionMinistry: {},
      secondOptionMinistryOptions: [],
      roles: {},
      roleOptions: [],
      departments: [],
      departmentOptions: [],
      churches: [],
      churchOptions: [],
      servolutionType: 0,
      required,
    }
  },
  async mounted() {
    // Get the user data
    const registrant = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${this.$route.params.id}`)
    console.log(registrant)

    // Servolution Type
    this.servolutionType = registrant.data.servolutionEvents.servolutionTypeId

    // Check if this data is eligible for edit or not
    if (!(registrant.data.servolutionRegistrationStatusId === 1 || registrant.data.servolutionRegistrationStatusId === 2 || registrant.data.servolutionRegistrationStatusId === 12 || registrant.data.servolutionRegistrationStatusId === 14)) {
      return this.$router.replace({ name: 'servolution-participants', params: { id: registrant.data.servolutionEventsId } })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: 'This data cannot be edited.',
            },
          })
        })
    }

    // Set basic data
    this.eventId = registrant.data.servolutionEventsId
    this.name = registrant.data.name
    this.birthDate = moment(registrant.data.birthDate).format('DD MMMM YYYY')
    this.gender = registrant.data.gender
    this.email = registrant.data.email
    this.phone = registrant.data.phone
    this.connectGroupCategory = registrant.data.connectGroupCategory

    // First option ministry
    this.firstOptionMinistry = {
      id: registrant.data.firstOptionMinistryScheduleId,
      ministryId: registrant.data.firstOptionMinistry,
    }
    if (!(_.isNull(registrant.data.firstOptionMinistrySchedule.auditionSchedule) && _.isNull(registrant.data.firstOptionMinistrySchedule.auditionRoom)) && !(_.isNull(registrant.data.firstOptionMinistrySchedule.interviewSchedule) && _.isNull(registrant.data.firstOptionMinistrySchedule.interviewRoom))) {
      this.firstOptionMinistry.name = `${registrant.data.firstMinistry.name} (Audisi di tanggal ${moment(registrant.data.firstOptionMinistrySchedule.auditionSchedule).format('DD MMMM YYYY')} pukul ${moment(registrant.data.firstOptionMinistrySchedule.auditionSchedule).format('HH:mm')} di ${registrant.data.firstOptionMinistrySchedule.auditionRoom} & Interview di tanggal ${moment(registrant.data.firstOptionMinistrySchedule.interviewSchedule).format('DD MMMM YYYY')} pukul ${moment(registrant.data.firstOptionMinistrySchedule.interviewSchedule).format('HH:mm')} di ${registrant.data.firstOptionMinistrySchedule.interviewRoom})`
    } else if (!(_.isNull(registrant.data.firstOptionMinistrySchedule.auditionSchedule) && _.isNull(registrant.data.firstOptionMinistrySchedule.auditionRoom)) && (_.isNull(registrant.data.firstOptionMinistrySchedule.interviewSchedule) || _.isNull(registrant.data.firstOptionMinistrySchedule.interviewRoom))) {
      this.firstOptionMinistry.name = `${registrant.data.firstMinistry.name} (Audisi di tanggal ${moment(registrant.data.firstOptionMinistrySchedule.auditionSchedule).format('DD MMMM YYYY')} pukul ${moment(registrant.data.firstOptionMinistrySchedule.auditionSchedule).format('HH:mm')} di ${registrant.data.firstOptionMinistrySchedule.auditionRoom} & Jadwal Interview akan dihubungi oleh PIC)`
    } else if ((_.isNull(registrant.data.firstOptionMinistrySchedule.auditionSchedule) || _.isNull(registrant.data.firstOptionMinistrySchedule.auditionRoom)) && !(_.isNull(registrant.data.firstOptionMinistrySchedule.interviewSchedule) && _.isNull(registrant.data.firstOptionMinistrySchedule.interviewRoom))) {
      this.firstOptionMinistry.name = `${registrant.data.firstMinistry.name} Jadwal Audisi akan dihubungi oleh PIC & Interview di tanggal ${moment(registrant.data.firstOptionMinistrySchedule.interviewSchedule).format('DD MMMM YYYY')} pukul ${moment(registrant.data.firstOptionMinistrySchedule.interviewSchedule).format('HH:mm')} di ${registrant.data.firstOptionMinistrySchedule.interviewRoom})`
    } else {
      this.firstOptionMinistry.name = `${registrant.data.firstMinistry.name} (Jadwal Audisi dan Interview akan dihubungi oleh PIC)`
    }

    // Second option ministry
    if (!(_.isNull(registrant.data.secondOptionMinistry) || _.isUndefined(registrant.data.secondOptionMinistry) || registrant.data.secondOptionMinistry === '')) {
      this.secondOptionMinistry = {
        id: registrant.data.secondOptionMinistryScheduleId,
        ministryId: registrant.data.secondOptionMinistry,
      }
      if (!(_.isNull(registrant.data.secondOptionMinistrySchedule.interviewSchedule) && _.isNull(registrant.data.secondOptionMinistrySchedule.interviewRoom))) {
        this.secondOptionMinistry.name = `${registrant.data.secondMinistry.name} (Interview di tanggal ${moment(registrant.data.secondOptionMinistrySchedule.interviewSchedule).format('DD MMMM YYYY')} pukul ${moment(registrant.data.secondOptionMinistrySchedule.interviewSchedule).format('HH:mm')} di ${registrant.data.secondOptionMinistrySchedule.interviewRoom})`
      } else {
        this.secondOptionMinistry.name = `${registrant.data.secondMinistry.name} (Jadwal Interview akan dihubungi oleh PIC)`
      }
    }

    // Get list of ministries
    const ministries = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/event/details/list/${this.eventId}/${registrant.data.churchId}`)
    this.firstOptionMinistryOptions = _.filter(ministries.data, o => o.ministry_type_id === 1 && o.ministry_type_id === 2).map(details => {
      const data = {}
      data.id = details.id
      data.ministryId = details.divisions_id

      if (!(_.isNull(details.audition_schedule) && _.isNull(details.audition_room)) && !(_.isNull(details.interview_schedule) && _.isNull(details.interview_room))) {
        data.name = `${details.name} (Audisi di tanggal ${moment(details.audition_schedule).format('DD MMMM YYYY')} pukul ${moment(details.audition_schedule).format('HH:mm')} di ${details.audition_room} & Interview di tanggal ${moment(details.interview_schedule).format('DD MMMM YYYY')} pukul ${moment(details.interview_schedule).format('HH:mm')} di ${details.interview_room})`
      } else if (!(_.isNull(details.audition_schedule) && _.isNull(details.audition_room)) && (_.isNull(details.interview_schedule) || _.isNull(details.interview_room))) {
        data.name = `${details.name} (Audisi di tanggal ${moment(details.audition_schedule).format('DD MMMM YYYY')} pukul ${moment(details.audition_schedule).format('HH:mm')} di ${details.audition_room} & Jadwal Interview akan dihubungi oleh PIC)`
      } else if ((_.isNull(details.audition_schedule) || _.isNull(details.audition_room)) && !(_.isNull(details.interview_schedule) && _.isNull(details.interview_room))) {
        data.name = `Jadwal Audisi akan dihubungi oleh PIC & Interview di tanggal ${moment(details.interview_schedule).format('DD MMMM YYYY')} pukul ${moment(details.interview_schedule).format('HH:mm')} di ${details.interview_room})`
      } else {
        data.name = `${details.name} (Jadwal Audisi dan Interview akan dihubungi oleh PIC)`
      }

      return data
    })
    this.secondOptionMinistryOptions = _.filter(ministries.data, o => o.ministry_type_id === 2).map(details => {
      const data = {}
      data.id = details.id
      data.ministryId = details.divisions_id

      if (!(_.isNull(details.interview_schedule) && _.isNull(details.interview_room))) {
        data.name = `${details.name} (Interview di tanggal ${moment(details.interview_schedule).format('DD MMMM YYYY')} pukul ${moment(details.interview_schedule).format('HH:mm')} di ${details.interview_room})`
      } else {
        data.name = `${details.name} (Jadwal Interview akan dihubungi oleh PIC)`
      }

      return data
    })

    // Hide loading
    this.isLoading = false
  },
  methods: {
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }
      
      // Submit
      this.$refs.frmEditParticipant.validate().then(success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('update', 'servolution_participants')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Update user data first
          this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${this.$route.params.id}`, {
            firstOptionMinistry: this.firstOptionMinistry.ministryId,
            firstOptionMinistryScheduleId: this.firstOptionMinistry.id,
            secondOptionMinistry: this.secondOptionMinistry ? this.secondOptionMinistry.ministryId : null,
            secondOptionMinistryScheduleId: this.secondOptionMinistry ? this.secondOptionMinistry.id : null,
            updatedAt: new Date(),
          }).then(async () => {
            // Hide the loading
            this.isLoading = false

            // Redirect
            this.$router.replace({ name: 'servolution-participants', params: { id: this.eventId } })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Data has been updated successfully.',
                  },
                })
              })
          })
        }
      })
    },
  },
}
</script>